import React, { useEffect, useRef, useState } from 'react'
import AppLayout from '../../layout/components/AppLayout'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.min.css';
import { Button} from '@mui/material';

import DateSelect from '../../components/AppInput/DateSelect';

import jobServices from '../jobServices';
import MasterServices from '../../services/masterServices';
import JobStatus from '../../job/components/JobStatus';
import { Link } from 'react-router-dom';
import moment from 'moment';



const CellLink = (props) => <Link to={`/job/details/${props.data.id}`}>{props.data.id }</Link>



export default function JobListPage() {

    const [masters,setMasters] = useState(null);
    const getMasterList = () => {
        MasterServices.get(["receving-mode","job-type","job-status","warranty-status","brand-list","product-list","problem-list","physically-check-list","box-condition-list","accessory-present-list"]).then(res=>{
            setMasters(res);
        });
    }

    const getMasterLabel = (type, id) => {
        let label = masters[type].find(m => m.value === id)
        return label.label;
    }

    useEffect(() => {
        getMasterList();
    }, [])


    const gridRef = useRef();
    const [defaultColDef] = useState({
        floatingFilter: true,
        filterParams: {
        buttons: ['apply'],
        },
        resizable: true,
        editable: true,
        width:80,
        wrapText: true,     // <-- HERE
        autoHeight: true,   // <-- & HERE    
        cellStyle: {fontSize: '11px', fontWeight:"bold", lineHeight:1, borderLeft:"#000 1px solid", borderTop:"#000 1px solid", padding:"1px",}
    })

    const rowClassRules = {
        'bg-success': params => params.data.jobStatus === 1,
        'bg-info': params => params.data.jobStatus === 3,
        'bg-warning' : params => params.data.jobStatus === 4,
    };
  

    const [columnDefs,setColumnDef] = useState();
    const prepairColumnDef = () => {
        let colDefs = [
            { field:"id",sortable: true, colId:"job.id",  filter:true, cellRenderer: CellLink, headerName: '#', width:50},
            { field:"firstName",sortable: true, filter:true,  colId:"customer.firstName", headerName:"CName",  width:80 },
            { field:"recevingMode",sortable: true, filter:true,  colId:"job.recevingMode", headerName:"Mode", valueGetter: params => getMasterLabel("receving-mode",params.data.recevingMode), width:80 },
            { field:"jobType",sortable: true, headerName:"Type", filter:true,  colId:"job.jobType", valueGetter: params => getMasterLabel("job-type",params.data.jobType), width:70 },
            { field:"model",sortable: true, filter:true,  colId:"product.model" },

            { headerName:"R. Date", field:"recevingDate",sortable: true, filter:true,  colId:"job.recevingDate", width:80, valueGetter: params => params.data.recevingDate!==null? moment(params.data.created_at).format("MM/DD/YYYY"):".." },
            { headerName:"S. Date", field:"serviceDate",sortable: true, filter:true,  colId:"job.serviceDate", width:80, valueGetter: params => params.data.serviceDate!==null?moment(params.data.serviceDate).format("MM/DD/YYYY"):".." },
            { headerName:"C. Date", field:"closeDate",sortable: true, filter:true,  colId:"job.closeDate", width:80, valueGetter: params => params.data.closeDate!==null?moment(params.data.closeDate).format("MM/DD/YYYY"):".." },
            { headerName:"H. Date", field:"handoverDate",sortable: true, filter:true,  colId:"job.handoverDate", width:80, valueGetter: params => params.data.handoverDate!==null?moment(params.data.handoverDate).format("MM/DD/YYYY"):".." },

            { field:"brand",sortable: true, filter:true,  colId:"brand_list.title", valueGetter: params => getMasterLabel("brand-list",params.data.brand), },
            { field:"product",sortable: true, filter:true,  colId:"product_list.title", valueGetter: params => getMasterLabel("product-list",params.data.product) },
            { field:"warrantyStatus",sortable: true,headerName:"WTY", filter:true,  colId:"job.warrantyStatus", width:70, valueGetter: params => getMasterLabel("warranty-status",params.data.warrantyStatus) },
            
            { field:"sno",sortable: true, filter:true,  colId:"product.sno", headerName:"R.Sno " ,  resizable: true,},
            { field:"newSrNo",sortable: true, filter:true,  colId:"product.newSrNo", headerName:"D.Sno" ,  resizable: true,},
            

            { field:"problem",sortable: true, filter:true,  colId:"problem_list.title", valueGetter: params => getMasterLabel("problem-list",params.data.problem) },
            //{ field:"physicallyCheck",sortable: true, filter:true,  colId:"product.physicallyCheck", valueGetter: params => getMasterLabel("physically-check-list",params.data.physicallyCheck) },
            //{ field:"boxCondition",sortable: true, filter:true,  colId:"product.boxCondition", valueGetter: params => getMasterLabel("box-condition-list",params.data.boxCondition) },
            //{ field:"accessoryPresent",sortable: true, filter:true,  colId:"product.accessoryPresent", valueGetter: params => getMasterLabel("accessory-present-list",params.data.accessoryPresent) },
            { field:"status",sortable: true, filter:true,  colId:"job_status.title", width:80, headerName:"Status", valueGetter: params => getMasterLabel("job-status",params.data.jobStatus) },
            { field:"status",sortable: true, colId:"job.status",  filter:false, cellRenderer: JobStatus, headerName: 'Update Status', width:160, cellRendererParams: { refreshList: getJobList }, editable: false},
            //{ field:"sno",sortable: true, filter:false,  colId:"product.sno", headerName:"Serial No." , width:300,  resizable: true,cellRenderer:srNoData },
        ];
        setColumnDef(colDefs)
    }


    const [filter,setFilter] = useState(null)

    const [jobList,setJobList] = useState(null);
    const getJobList =  () => {
        jobServices.getFilteredList(filter).then(res=>{
            setJobList(res.data);
        })
    }

    useEffect(()=>{
        getJobList();
    },[filter])

    useEffect(()=>{
        if(masters) {
          prepairColumnDef()
        }
    },[masters])

    const onBtnExport = () => {
        gridRef.current.api.exportDataAsCsv("data.csv");
    };


    return (
    <AppLayout>
        <div className="pt-2">
            <div className="container-fluid">

                <div className="row my-2">
                    <div className='col-md-6'>
                    </div>
                    
                    <div className="col-md-6">
                        <button className="btn btn-primary float-end mx-2" onClick={onBtnExport} >Export</button>
                        <span className="float-end"><DateSelect filter={filter} setFilter={setFilter} /></span>
                    </div>
                </div>
                <div className="row">
                <div className="col-md-12">
                        <div className="ag-theme-balham" style={{height: 650}}>
                            <AgGridReact
                                ref={gridRef}
                                rowData={jobList}
                                columnDefs={columnDefs}
                                defaultColDef={defaultColDef}
                                rowClassRules={rowClassRules}
                                animateRows={true}
                                onGridReady={getJobList}
                            />
                        </div>
                    </div>
                </div>
                    
            </div>
        </div>
    </AppLayout>
    )
}