import api from "../api/api"


const  serialize = (obj) =>{
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
}



const get = (q) =>{
    return new Promise((resolve, reject)=>{
        api.get("/job"+q).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const getFilteredList = (filter) =>{
    return new Promise((resolve, reject)=>{
        api.get("/job/data/list?"+serialize(filter)).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const getById = (id)=>{
    return new Promise((resolve, reject)=>{
        api.get("/job/"+id).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const getActivity = (id)=>{
    return new Promise((resolve, reject)=>{
        api.get("/job/activity/"+id).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const save = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("/job",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
        }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
        })
    })
}

const update = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.put("/job",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
        }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
        })
    })
}


const remove = (payload) => {
    return new Promise((resolve, reject)=>{
        api.delete("/job/"+payload.id).then( (res)=>{
            if(!res.data.error){
                resolve(res);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const customerSearch = (q) =>{
    return new Promise((resolve, reject)=>{
        api.get("/customer/search"+q).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const updateStatus = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("/job/update-status",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
        }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
        })
    })
}

const getCustomerById = (id)=>{
    return new Promise((resolve, reject)=>{
        api.get("/customer/"+id).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const getJobStatusColor = (status) =>{
    let colors = ["bg-danger text-white","bg-warning text-dark","bg-success text-white","bg-secondary text-white"];
    return colors[status];
}

const printReceipt = (customerId, selectedIds) =>{
    let q = "?ids="+selectedIds.toString()
    return new Promise((resolve, reject)=>{
        api.get("/receipt/"+customerId+"/print-receipt"+q).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const emailReceipt = (customerId, selectedIds) =>{
    let q = "?ids="+selectedIds.toString()
    return new Promise((resolve, reject)=>{
        api.get("/receipt/"+customerId+"/email-receipt"+q).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const handoverReceipt = (customerId, selectedIds) =>{
    let q = "?ids="+selectedIds.toString()
    return new Promise((resolve, reject)=>{
        api.get("/receipt/"+customerId+"/email-handover"+q).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const jobServices = {
    get,
    getFilteredList,
    getById,
    getCustomerById,
    getActivity,
    save,
    update,
    remove,
    customerSearch,
    updateStatus,
    getJobStatusColor,
    printReceipt,
    emailReceipt,
    handoverReceipt,
}

export default jobServices;